/* table of contents */

/* 1. variables*/
/* 2. global styles */
/* 3. layout */
/* 4. components */
/* 5. utilities */

/* ================
	 1. variables
   ================ */

.body-light {
	--bg: #f5f6f7;
	--bg-alt: #fff;
	--fg: #444;
	--fg-alt: #222;
	--primary: #306cc4;
	--primary-alt: #185dc7;
	--primary-light: rgba(44, 93, 206, 0.1);
	--container-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.body-dark {
	--bg: #030217;
	--bg-alt: rgb(22 28 41 / 33%);
	--fg: #c7ffff;
	--fg-alt: #d7ffff;
	--primary: #306cc4;
	--primary-alt: #185dc7;
	--primary-light: rgb(18 17 49 / 44%);
	--container-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

/* =====================
     2. global styles
   ===================== */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
}

::-moz-selection {
	background: var(--primary);
	color: var(--bg);
}

::-webkit-selection,
::selection {
	background: var(--primary);
	color: var(--bg);
}

::-webkit-scrollbar {
	width: 16px;
}

::-webkit-scrollbar-track {
	background-color: var(--bg-alt);
}

::-webkit-scrollbar-thumb {
	border: 4px solid var(--bg);
	background-color: var(--primary);
	border-radius: 0.5em;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--primary-alt);
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0 auto;
	max-width: 1100px;
	width: 95%;
	background-color: var(--bg);
	color: var(--fg);
	font-family: 'Poppins', sans-serif;
	line-height: 1.5;
	transition: background-color 0.1s linear;
}

h1,
h2,
h3,
h4 {
	color: var(--fg-alt);
	line-height: 1.1;
}

h5,
h6 {
	color: var(--fg);
	line-height: 1.1;
}

h1 {
	font-size: 5rem;
}

h2 {
	font-size: 1.8em;
}

ul {
	list-style-type: none;
}

button {
	cursor: pointer;
	font-family: inherit;
	background-color: inherit;
}

a {
	text-decoration: none;
}

.link {
	padding: 0 0 0.12em 0;
	position: relative;
	color: var(--primary);
}

.link:hover {
	color: var(--primary);
}

.link:active {
	color: var(--primary-alt);
}

.link::before {
	content: '';
	display: inline;
	width: 0%;
	height: 0.12em;
	position: absolute;
	bottom: 0;
	background-color: var(--primary);
	transition: width 0.2s ease-out;
}

.link:hover::before {
	width: 100%;
}

@media (max-width: 768px) {
	h1 {
		font-size: 4rem;
	}
}

@media (max-width: 580px) {
	h2 {
		font-size: 1.5rem;
	}
}

@media (max-width: 320px) {
	h1 {
		font-size: 3rem;
	}
}

/* =============
     3. layout
   ============= */

header,
nav,
nav ul,
.hero,
.contact ul,
footer {
	display: flex;
	align-items: center;
}

header {
	height: 6em;
	justify-content: space-between;
}

nav li {
	font-weight: 500;
	margin: 0 0.5em;
}

.hero {
	margin-top: 6em;
	justify-content: space-between;
}

.hero h1 {
	display: flex;
	flex-direction: column;
	flex-basis: 50%;
}

.hero-name {
	color: var(--primary);
}

.hero-about {
	flex-basis: 40%;
}

.hero-about p {
	font-size: 1.1rem;
}

.hero-about .btn {
	margin-top: 2.2em;
}

section {
	margin-top: 5em;
}

.section-title {
	padding-top: 0.5em;
	margin-bottom: 1.5em;
	text-align: center;
	text-transform: uppercase;
}

.section-title::after {
	content: '';
	margin: 0.3em auto 0 auto;
	display: block;
	width: 1.5em;
	border-bottom: 0.2em solid var(--primary);
}

.project-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--bg-alt);
	max-width: 950px;
	width: 95%;
	margin: 2em auto;
	padding: 2em;
	text-align: center;
	box-shadow: var(--container-shadow);
	border-radius: 1em;
}

.project img {
	border-radius: 0.5em;
}

.project-content {
	flex-basis: 50%;
	margin-left: 2em;
}

.project-content p {
	margin: 1.2em 0;
}

.project-content p a {
	color: var(--primary);
}

.project-skill,
.project-link {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.project-skill {
	margin: 0 auto;
}

.project-skill li {
	font-size: 0.8rem;
	font-weight: 700;
	margin: 0.3em 0.2em;
	padding: 0.4em 0.6em;
	border-radius: 5em;
	background-color: var(--primary-light);
	color: var(--primary-alt);
}

.project-link {
	margin-top: 1.2em;
}

.stack-container {
	margin: 0 auto;
	display: flex;
	gap: 1em;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.tech-container {
	width: 10em;
	height: 10em;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--bg-alt);
	border-radius: 1.5em;
	box-shadow: var(--container-shadow);
	overflow: hidden;
	font-size: 0.8rem;
	font-weight: 500;
}

.tech-container::before {
	content: '';
	position: absolute;
	bottom: 0px;
	left: 0px;
	transform: scaleX(0);
	width: 100%;
	height: 6px;
	border-radius: 1.5;
	background-color: var(--primary-alt);
	transition: all 0.2s linear;
}

.tech-container:hover::before {
	transform: scaleX(1);
}

.tech-container i {
	font-size: 3rem;
	margin-bottom: 0.2em;
}

.contact ul {
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.3em;
}

.contact .btn {
	margin-right: 0.5em;
	margin-top: 0.3em;
}

.contact .icon,
.hero .icon {
	margin-right: 0.2em;
}

footer {
	margin-top: 5em;
	height: 7em;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	left: 0;
	right: 0;
	background-color: var(--bg-alt);
	box-shadow: 0 -0.1em 1em rgb(0, 0, 0, 0.1);
}

footer ul {
	display: flex;
}

footer small {
	font-weight: 500;
	margin-top: 0.5em;
}

@media (max-width: 950px) {
	.project-container {
		flex-direction: column;
		max-width: 450px;
		padding: 1.5em;
	}

	.project img {
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
		margin-bottom: 2em;
	}

	.project-content {
		padding: 0;
		margin-left: 0;
	}
}

@media (max-width: 580px) {
	nav ul {
		flex-direction: column;
		padding: 4em 0;
		position: absolute;
		right: 0;
		left: 0;
		top: 5em;
		background-color: var(--bg);
		width: 0;
		overflow: hidden;
		transition: width 0.2s ease-in-out;
	}

	.display-nav-list {
		width: 100%;
	}

	nav li {
		margin: 0.5em 0;
	}

	.hero,
	.hero-about,
	section {
		margin-top: 2.5em;
	}

	.hero {
		padding-bottom: 1em;
		flex-direction: column;
	}

	.hero h1,
	.hero-about {
		width: 100%;
	}

	.project .section-title {
		margin-bottom: 0;
	}

	.stack-container i {
		font-size: 3rem;
	}
}

/* =================
     4. components
   ================= */

/* buttons */

.btn {
	display: inline-block;
	border-radius: 5em;
	font-size: 0.8rem;
	font-weight: 600;
	transition: transform 0.2s ease-in-out;
	padding: 1em 1.6em;
	background-color: var(--primary);
	color: var(--bg);
}

.btn:hover {
	background-color: var(--primary-alt);
}

.btn:active {
	transform: translateY(-0.4em);
}

/* icons */

.icon-normal,
.icon-border {
	margin: 0.2em;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
	cursor: pointer;
	border-radius: 50%;
	font-size: 1.2rem;
}

.icon-normal:hover,
.icon-border:hover {
	color: var(--bg);
}

.icon-normal::after,
.icon-border::after {
	content: '';
	position: absolute;
	width: 0%;
	height: 0%;
	z-index: -1;
	background-color: var(--primary);
	border-radius: 50%;
	transition: all 0.25s ease-in-out;
}

.icon-normal:hover::after,
.icon-border:hover::after {
	width: 100%;
	height: 100%;
	border: 0.2em solid var(--primary);
}

.icon-normal {
	width: 1.6em;
	height: 1.6em;
	color: var(--fg-alt);
}

.icon-border {
	width: 2.2em;
	height: 2.2em;
	color: var(--primary);
	border: 0.15em solid var(--primary);
}

/* icons */

.scroll-container {
	display: flex;
	justify-content: flex-end;
}

.scroll-up {
	display: none;
	position: fixed;
	font-size: 0.9rem;
	bottom: 3em;
	right: 2em;
	font-weight: 600;
	transition: transform 0.2s ease-in-out;
}

.scroll-up:active {
	transform: translateY(0.4em);
}

.scroll-up a {
	background-color: var(--primary);
	padding: 0.9em 1.2em;
	color: var(--bg);
	border-radius: 50%;
}

.scroll-up a:hover {
	background-color: var(--primary-alt);
}

@media (max-width: 1000px) {
	.scroll-container {
		display: none;
	}
}

/* ============
   5. utilities
   ============ */

.fa-bars {
	display: none;
}

.header-title {
	font-weight: 700;
	text-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
}

nav .link {
	color: var(--fg-alt);
}

@media (max-width: 580px) {
	.fa-bars {
		display: flex;
	}
}